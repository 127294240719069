import React from 'react'

export default function About(){
    return (
        <div align="center">
            <img src={require('../../resources/head-1.jpg')} alt="header 1" width="100%" height="350px" />
            <br/><br/>
            Kios Software speciailizes in productivity software. Let us make your life easier! (Coming soon)
        </div>
    )
}