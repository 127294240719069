import axios from 'axios'

// Development URL
let URL = "https://3k2usm3hi3.execute-api.us-east-1.amazonaws.com/development"

export const getOauthToken = (code) => {
    axios.get(`${URL}/auth/generate?code=${code}`)
        .then(res=> { 
            console.log(res)
            if(res.data.id_token){
                localStorage.setItem('token', JSON.stringify(res.data))
                console.log('token saved: %O', localStorage.getItem('token'))
            }  
        })
        .catch(err => {
            console.error(err)
        })
}

export const validateToken = (jwt) => {
    let parsed = JSON.parse(jwt)
    axios.get(`${URL}/auth/validate?code=${parsed.id_token}`, {
            headers: { 
                "authorizationToken": parsed.id_token
            }
        })
        .then(res => {
            if(res.data.valid){ 
                // token is valid
                console.log('token is valid and can stay')
            } else {
                // thats a bad token
                localStorage.removeItem('token')
            }
        })

}

export const validateCode = (code, callback) =>{ 
    axios.get(`${URL}/auth/generate?code=${code}&verify=${true}`)
        .then(res => {
            if(res.status === 200){
                callback(true)
            } else {
                callback(false)
            }
        })
}