import React, { useState } from 'react'
import Homepage from '../homepage/Homepage'
import Product from '../product/Product'
import NotFound from '../notfound/NotFound'
import About from '../about/About'
import Navbar from '../navbar/Navbar'
import Login from '../login/Login'
import { Switch, Route } from 'react-router-dom'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core'
import AppSelect from '../../appselect/AppSelect'

const theme = createMuiTheme({
    palette: {
        primary: {
            500: '#4760ff'
        }
    }
})

export default function App(props){
    const [loggedin, setLoggedin] = useState(false)
    
    return (
        <MuiThemeProvider theme={theme}>
            <div>
                <Navbar
                    loggedin={loggedin}/>
                <Switch>
                    <Route exact path="/" component={({ location }) => 
                    <Homepage 
                        location={location}
                        setLoggedin={setLoggedin}/>}/>
                    <Route exact path="/product" component={Product}/>
                    <Route exact path="/about" component={About}/>
                    <Route exact path="/register" component={() => <div>Not yet implemented</div>}/>
                    <Route exact path="/login" component={Login}/>
                    <Route exact path="/applications" component={({ location }) => <AppSelect loggedin={loggedin}/>}/>
                    <Route component={NotFound}/>
                </Switch>
            </div>
        </MuiThemeProvider>
    )
}