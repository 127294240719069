import React from 'react'
import { Button } from '@material-ui/core'

let url = "https://kios-gidp.auth.us-east-1.amazoncognito.com/login?response_type=code&client_id=2lhs1j0ndljittj7294mvd5dnp&redirect_uri=https://www.kioshq.com"

export default function Login(props){
    return (
        <div align="center">
            Please login below:<br/><br/><br/>
            <Button href={`${url}`}>Login Here</Button>
        </div>
    )
}