import React from 'react'
import { Link } from 'react-router-dom'

export default function NotFound(props){
    return (
        <div align="center">
            <h1>404 Not Found</h1>
            <Link to="/">Click here to return home</Link>
        </div>
    )
}