import React, { useEffect } from 'react'
import qs from 'query-string'
import { validateCode } from '../../services/auth-service'

export default function Homepage(props){
    useEffect(() => {
        if(localStorage.getItem("code")) {
            validateCode(localStorage.getItem("code"), (response) => {
                if(response) props.setLoggedin(response)
                else localStorage.removeItem("code")
            })
        } else {
            let query = qs.parse(props.location.search)
            if(query.code){
                validateCode(query.code, (response) =>{
                    if(response) {
                        localStorage.setItem("code", query.code)
                    } 
                    props.setLoggedin(response)
                })
            }
        }
        //eslint-disable-next-line
    }, [props.location.search, props.setLoggedin])

    return (
        <div align="center">
            <div style={{ backgroundImage: `url(${require('../../resources/randy-fath-ymf4_9Y9S_A-unsplash.jpg')})`, width: '100%', height: '350px', backgroundPosition: '0% 35%' }} />
            <br/>
            <br/>
            We're hard at work building Kios Software for you - but we're not quite ready yet. Please check back soon!
        </div>
    )
}