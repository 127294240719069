import React, { useEffect } from 'react'
import { Paper, Grid, Button, Typography } from '@material-ui/core'
import history from '../util/history'

export default function AppSelect(props){
    useEffect(() => {
        if(!props.loggedin) {
            history.push("/")
        }
    }, [props.loggedin])
    return (
        <div align="center">
            <Paper style={{ width: '30vw', height: '50vh'}}>
                <Grid container direction="column" justify="center" alignContent="center" alignItems="center" spacing={2}>
                    <Grid item>
                        <Typography variant="h5">Application Select</Typography>
                    </Grid>
                    <Grid item>
                        <Button variant="outlined" color="primary" onClick={() => window.location.href = "https://backup.kioshq.com"}>Backup</Button>
                    </Grid>
                </Grid>
            </Paper>
        </div>
    )
}