import React from 'react'
import { makeStyles, Toolbar, AppBar, Typography, Button, Tooltip } from '@material-ui/core'
import { Link } from 'react-router-dom'
const useStyles = makeStyles({
    navlink: {
        textDecoration: 'none',
        color: 'inherit',
        '&:hover': {
            color: 'red'
        }
    },
    title: {
        flexGrow: 1
    }
})

export default function Navbar(props){ 
    const classes = useStyles()
    return (
        <AppBar
            position="static"
            elevation={0}
            color="inherit"
            style={{
                paddingLeft: '20vw',
                paddingRight: '20vw'
            }}>
            <Toolbar>
                <Typography variant="body1" style={{ paddingRight: '15px'}}><Link to="/" className={classes.navlink}>Kios Software</Link></Typography>
                <Typography variant="body1" className={classes.title}><Link to="/about" className={classes.navlink}>About</Link></Typography>
                {props.loggedin?(
                    <div>
                        <Tooltip title="Enjoy Kios Software!" placement="bottom">
                            <Button variant="outlined" color="primary"><Link to="/applications" style={{ textDecoration: 'none', color: 'inherit' }}>Access Applications</Link></Button>
                        </Tooltip>
                    </div>
                ):(
                    <div>
                        <Tooltip title="Thanks for your interest! We're not quite ready yet!" placement="bottom">
                            <Button variant="outlined" color="primary">Sign up</Button>
                        </Tooltip>
                        <Tooltip title="Thanks for your interest! We're not quite ready yet!" placement="bottom">
                            <Button variant="text">Sign in</Button>
                        </Tooltip>
                    </div>
                )}
            </Toolbar>
        </AppBar>
    )
}