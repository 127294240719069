import React, { useEffect, useState } from 'react'
import qs from 'query-string'
import { Grid } from '@material-ui/core'
import { Link } from 'react-router-dom'

const products = [
    {
        "name": "Backup",
        "description": "Kios Backup is an easy and secure way to back up your most important files to the cloud. More to come.",
        "path": "backup"
    },
    {
        "name": "Contact Center",
        "description": "Kios Contact Center unites organizations and increases communication. More to come.",
        "path": "contactcenter"
    }
]

export default function Product(props){
    const [selected, setSelected] = useState(0)

    useEffect(() => {
        let queryString = qs.parse(props.location.search)
        console.log(queryString)
    }, [props.location.search])

    return (
        <div>
            <Grid container direction="column" spacing={2} justify="center" alignContent="center" alignItems="center">
                <Grid container direction="row" spacing={2} justify="center">
                    {products.map((p, i)=>{
                        return (
                            <Grid item key={i} onClick={() => setSelected(i)}><Link to={`/product?p=${p.path}`} 
                                style={{ textDecoration: 'none', color: 'inherit'}}>{p.name}</Link></Grid>
                        )
                    })}
                </Grid>
                <Grid item>
                    {products[selected].description}
                </Grid>
            </Grid>
        </div>
    )
}